import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { internalPaths } from 'navigation';

const Home = lazy(() => import('pages/Home'));
const SpecificCourse = lazy(() => import('pages/SpecificCourse'));
const OurCourses = lazy(() => import('pages/OurCourses'));
const Workshops = lazy(() => import('pages/Workshops'));
const PromotionsEvents = lazy(() => import('pages/PromotionsEvents'));
const LocationsContact = lazy(() => import('pages/LocationsContact'));
const FAQ = lazy(() => import('pages/FAQ'));
const Inscriptions = lazy(() => import('pages/Inscriptions'));
const PaymentDone = lazy(() => import('pages/PaymentDone'));

const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <Routes>
          <Route path={internalPaths.home} element={<Home />} />
          <Route path={internalPaths.specificCourse} element={<SpecificCourse />} />
          <Route path={internalPaths.ourClasses} element={<OurCourses />} />
          <Route path={internalPaths.workshops} element={<Workshops />} />
          <Route path={internalPaths.promotionsEvents} element={<PromotionsEvents />} />
          <Route path={internalPaths.locationsContact} element={<LocationsContact />} />
          <Route path={internalPaths.faq} element={<FAQ />} />
          <Route path={internalPaths.inscription} element={<Inscriptions />} />
          <Route path={internalPaths.paymentDone} element={<PaymentDone />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
