export const internalPaths = {
  home: '/',
  specificCourse: '/clases/:course',
  aboutUs: '/nosotros',
  ourClasses: '/clases',
  workshops: '/talleres',
  promotionsEvents: '/eventos',
  locationsContact: '/sucursales',
  faq: '/dudas',
  inscription: '/inscripcion',
  paymentDone: '/pago-realizado',
};
