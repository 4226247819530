export const addFonts = () => {
  window.addEventListener('load', () => {
    var head = document.getElementsByTagName('head')[0];
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.as = 'style';
    link.href = 'https://candelaypicapica.s3.us-east-2.amazonaws.com/fonts.css';
    head.appendChild(link);
  });
};
